.quantity-dropdown-options::-webkit-scrollbar,
.medi_brands_dropdown::-webkit-scrollbar,
.medi_left_section::-webkit-scrollbar,
.medi_right_section::-webkit-scrollbar,
.medi_dropdown::-webkit-scrollbar,
.medi_right_container::-webkit-scrollbar {
  display: none;
}

.medi_right_container {
  background-color: #fff;
  top: 88px;
  left: 443px;
  right: 20px;
  bottom: 20px;
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  overflow: hidden;
  border-right: 1px solid #989898;
  border-radius: 0px 32px 32px 0px;
}

.medi_right_section {
  width: 100%;
  padding-top: 10px;
  padding-left: 32px;
  padding-bottom: 56px;
  position: relative;
  overflow: auto;
}

.medi_right_content,
.medi_right_top {
  display: flex;
  flex-direction: column;
}

.medi_right_top {
  width: 640px;
  gap: 24px;
}

.medi_right_top .backLink {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 10px;
  background-color: #66D348;
  width: 22%;
  height: 40px;
  color: white;
  border-radius: 6px;
}

.medi_right_heading {
  border: none;
  font-size: 28px;
  color: #023047;
  font-weight: 600;
  font-family: inherit;
  outline: none;
}

/* Adjust other styles as needed */

.medi_right_content {
  gap: 32px;
  width: auto;
}

.medi-right_box-back {
  display: flex;
  align-items: center;
  gap: 30px;
}

.medi_right_top {
  padding-top: 25px;
}

.medi_input_search {
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  height: 22px;
}

.medi_search_inputbox {
  display: flex;
  /* justify-content: space-between; */
  gap: 80px;
  width: 180% !important;
}

.medi_search_input {
  display: flex;
  width: 400px;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  padding: 0 8px;
  border: 1px solid #989898;
  border-radius: 6px;
  color: #989898;
  position: relative;
}

.medi_input_search::placeholder {
  color: #989898;
}

/*  MEDICATION TABLE STYLES HERE */
.medi_table_container-lab,
.medi_table_container-medication {
  display: flex;
  margin: 0;
  padding: 0;
  width: 180%;
  height: auto;
}

.medi_table_content-medication {
  border: 1px solid #989898;
  border-radius: 6px;
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.medi_tableheader-medication,
.medi_tableheader-lab {
  background-color: #3cb9d4;
}

.medi_table-lab,
.medi_table-medication {
  border-collapse: collapse;
  width: 100%;
}

.medi_table-lab td,
.medi_table-medication td {
  border-left: 1px solid #bcb9b9;
  border-bottom: 1px solid #bcb9b9;
  /* width: 156px; */
}

.medi_table-lab td:first-child,
.medi_table-medication td:first-child {
  width: 88px;
  border-left: none;
}

.theBody-tr_lab:last-child td,
.theBody-tr_medication:last-child td {
  border-bottom: none;
}

.medi_header_text-lab,
.medi_header_text-medication {
  text-align: center;
  padding: 12px 16px;
  font-size: 20px;
  color: #fff;
}

.medi_table_el-lab,
.medi_table_el-medication {
  text-align: center;
  flex-direction: column;
  font-size: 14px;
  height: 48px;
}

/* LAB TABLE STYLE HERE */
.medi_table_content-lab {
  border: 1px solid #989898;
  border-radius: 6px;
  width: 70%;
  height: fit-content;
  overflow: hidden;
}



/* SUGGESTION BOX STYLES HERE */
.medi_suggestionbox {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 8px;
  width: 805px;
  height: 159px;
  font-size: 16px;
  line-height: 20px;
}

.medi_suggestion_label {
  color: #023047;
  font-weight: 500;
}

.medi_suggestion_input {
  width: 100%;
  border: 1px solid #989898;
  border-radius: 6px;
  height: 135px;
  padding: 16px 12px 36px 12px;
  line-height: 1.4;
  overflow: hidden;
  resize: none;
  outline: none;
}

/* BUTTON SAVE */
.btn_box {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding: 80px 56px 0 0;
}

.medi_btn_deletebox,
.medi_btn_savebox {
  background-color: #66d348;
  width: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  padding: 12px 40px;
  color: #fff;
  z-index: 999;
}

.medi_btn_deletetext,
.medi_btn_savetext {
  font-size: 20px;
  position: relative;
  font-weight: 500;
}

.medi_btn_deletebox {
  background-color: rgb(234, 36, 36);
}

/* //////////////////////////////////////////////// */
/* DROP DOWN */

.option_rec_box {
  background-color: #d5f3f8;
}

.medi_dropdown {
  color: #000;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: -1px;
  width: 280px;
  height: fit-content;
  z-index: 1;
  border: 1px solid #989898;
  border-top: none;
  border-radius: 0 0 6px 6px;
  outline: none;
  box-sizing: border-box;
  overflow: auto;
}

.medi_option {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 32px;
  /* height: 48px; */
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
}

.option_rec_box:hover,
.medi_option:hover {
  background-color: #dcdcdc;
  color: #023047;
}

.option_rec_box:hover .rec_text {
  color: #fff;
  background-color: #989898;
}

.option_rec_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}

.option_rec_box .medi_option {
  border-bottom: none;
}

.medi_option:last-child {
  border-bottom: none;
}

.rec_text {
  color: #fff;
  background-color: #3cb9d4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
}

.medi_brands_dropdown {
  color: #000;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: -1px;
  margin-top: 10px;
  width: 350px !important;
  height: fit-content;
  z-index: 1;
  /* border: 1px solid #e4dcdc; */
  border-top: none;
  border-radius: 6px;
  outline: none;
  box-sizing: border-box;
  overflow: auto;
}

.no_medicine_selected {
  font-size: 20px;
  padding: 8px 0;
  text-align: center;
}

/* SEARCH DROPDOWN STYLE  */

.medicine-search-results_medication {
  overflow-y: auto;
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 999;
  cursor: pointer;
  top: 50px;
  height: 400px;
  width: 100%;
  margin-left: -8px;
}

.medicine-search-results_medication::-webkit-scrollbar {
  display: none;
}

/* .medicine-table-wrapper::-webkit-scrollbar {
  display: none;
} */

.medicine-brandsearch_medication {
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
}

.medicine-brandsearch_medication:hover {
  background-color: #beebf5;
}

/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Close button */
.popup-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #3cb9d4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background: #023047;
}