.clinic-signup-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clinic-signup-login_container {
  height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 45px;
  padding: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  overflow: hidden;
}

.clinic-signup-left_container-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 40px 100px;
  border-radius: 30px;
  background-color: #1f657f;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  box-sizing: border-box;
}

.clinic-signup-left_container-section > h1 {
  font-size: 50px;
  line-height: 60px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  font-family: "Maven Pro", sans-serif;
  margin-bottom: 20px;
}

.clinic-signup-left_container-section > img {
  max-width: 100%;
  height: auto;
}

.clinic-signup-right_container-section {
  width: 50%;
  padding: 50px 100px;
  box-sizing: border-box;
  overflow-y: auto;
}

.clinic-signup-right_container-section::-webkit-scrollbar {
  display: none;
}

.clinic-signup-hello-agin_sign {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 20px;
}

.clinic-signup-input-section {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 25px;
}

.clinic-signup-input-section > input,
.clinic-signup-input-section > textarea {
  width: 100%;
  outline: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgb(202, 202, 202);
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.clinic-signup-input-section > input:hover,
.clinic-signup-input-section > textarea:hover {
  border-color: #3cb9d4;
  box-shadow: 0 0 5px rgba(60, 185, 212, 0.5);
}

.clinic-signup-input-section_sign {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 15px;
}

.clinic-signup-input-section_sign > input {
  flex: 1;
  outline: none;
  padding: 12px;
  font-size: 19px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgb(202, 202, 202);
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.clinic-signup-input-section_sign > input:hover {
  border-color: #3cb9d4;
  box-shadow: 0 0 5px rgba(60, 185, 212, 0.5);
}

.clinic-signup-search-button,
.clinic-signup-add-button {
  border: none;
  padding: 15px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clinic-signup-search-button {
  background-color: #3cb9d4;
}

.clinic-signup-search-button:hover {
  background-color: #2da5ba;
}

.clinic-signup-add-button {
  background-color: #4caf50;
}

.clinic-signup-add-button:hover {
  background-color: #45a049;
}

.clinic-signup-login_footer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 25px;
}

.clinic-signup-login-button {
  border: none;
  padding: 15px 0px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #3cb9d4;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clinic-signup-login-button:hover {
  background-color: #2da5ba;
}

.clinic-signup-cancel-button {
  border: none;
  padding: 15px 0px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #c44242;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clinic-signup-cancel-button:hover {
  background-color: #9f3636;
}

.clinic-signup-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.clinic-signup-blurred {
  filter: blur(5px);
  pointer-events: none;
}

.clinic-search-dropdown {
  margin-top: 20px;
}

.clinic-signup-multiselect {
  margin-top: 10px;
}

.clinic-signup-logo-upload {
  margin-top: 10px;
}

.time-slot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
}

.time-slot>input[type="time"]{
  flex: 1;
  outline: none;
  font-size: 19px;
  border-radius: 6px;
  padding: 0.3rem 0 0.3rem 0.8rem;
  border: 1px solid rgb(202, 202, 202);
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.clinic-signup-remove-time-slot-button {
  background-color: #c44242;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 5px;
}

.clinic-signup-remove-time-slot-button:hover {
  background-color: #9f3636;
}

.clinic-signup-add-time-slot-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clinic-signup-add-time-slot-button:hover {
  background-color: #45a049;
}
.clinic-search-results {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 10px;
box-shadow: 0px 0px 10px 10px rgb(228, 228, 228);
padding: 10px;
}

.clinic-search-result-item {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #f8f8f8;
}


.clinic-search-no-result {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.clinic-search-results::-webkit-scrollbar {
  display: none;
}