.forgot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 30%;
  height: 55%;
  gap: 25px;
  border-radius: 6%;
  -webkit-border-radius: 6%;
  -moz-border-radius: 6%;
  -ms-border-radius: 6%;
  -o-border-radius: 6%;
}

.forgot-container > h1 {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 800;
}

.forgot-container > input {
  width: 70%;
  padding: 14px 10px;
  outline: none;
  border: 1px solid gray;
  border-radius: 6px;
}

.forgot-container > button {
  width: 70%;
  padding: 14px 10px;
  background-color: #3cb9d4;
  border: none;
  border-radius: 6px;
  color: white;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
}
