.chat_container {
    background-color: #F8FBFC;
    Width: auto;
    height: auto;
    padding: 32px;
    position: fixed;
    top: 88px;
    left: 112px;
    right: 20px;
    bottom: 10px;
    border-radius: 32px;
    box-sizing: border-box;
    margin-bottom: 10px;
}