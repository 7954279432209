* {
  padding: 0;
  margin: 0;
}

.review_card_container::-webkit-scrollbar,
.doctor_profile_container::-webkit-scrollbar {
  display: none;
}

.doctor_profile_container {
  background-color: #fff;
  top: 88px;
  left: 112px;
  right: 20px;
  bottom: 20px;
  width: auto;
  height: auto;
  position: absolute;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 32px;
  overflow-y: auto;
  text-align: left;
}

.doctor_profile_container .backLink {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 10px;
  background-color: #66D348;
  width: 10%;
  height: 40px;
  color: white;
  border-radius: 6px;
}

.doc_edit_buttonbox {
  position: absolute;
  top: -32px;
  right: 42px;
}

.doc_edit_button {
  cursor: pointer;
  background: none;
  color: #525252;
  border: none;
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
}

.doc_save_button {
  cursor: pointer;
  background: none;
  background-color: #66d348;
  color: #fff;
  padding: 12px 40px;
  border-radius: 6px;
  border: none;
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* Top section */

.doctor_profile_container h3 {
  color: #023047;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.doctor_profile_container .doctor_role {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  font-family: inherit;
  line-height: 32px;
}

.doctor_edu_list,
.doctor_spec_list,
.doctor_profile_container p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  font-family: inherit;
  line-height: 24px;
}

.doctor_edu_list>span {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

.star_icon {
  width: 36px;
  height: 36px;
}

.doctor_profile_section {
  display: flex;
  flex-direction: column;
  gap: 52px;
}

.doctor_image {
  width: 216px;
  height: 216px;
}

.doctor_top_container {
  display: flex;
  align-items: center;
  gap: 48px;
  border-bottom: 1px solid #989898;
  padding: 24px 0 34px 24px;

  /* justify-content: center; */
}

.doctor_info_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.star_icon_container,
.doctor_rating_box {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////// */
.doctor_mid_section {
  display: flex;
  gap: 101px;
  position: relative;
}

.doctor_left_container {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 24px;
}

.doctor_intro_container {
  width: 580px;
  display: flex;
  flex-direction: column;
  gap: 52px;
}

.doctor_intro {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.doctor_education,
.doctor_specialization {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.doctor_edu_lists,
.doctor_spec_lists {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 36px;
}

.doctor_edu_list,
.doctor_spec_list {
  font-family: inherit;
  list-style-type: disc;
}

/* ////////////////////////////////////////////////////////////////////////////////// */
/* Right Section */
.doctor_right_container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 32px;
}

.doctor_exp_content_box {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  /* gap: 48px; */
}

.doctor_experience_box {
  width: 423px;
  border-radius: 24px;
  background-color: #d5f3f8;
}

.doctor_exp_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.doctor_profile_container .doctor_text {
  color: #023047;
  font-size: 22px;
  /* font-weight: 500; */
  line-height: 32px;
}

.doctor_address_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 340px;
  padding-left: 24px;
}

.doctor_address_content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.doctor_address_heading {
  color: #1e7e9c;
  font-size: 20px;
  font-weight: 600;
}

.doctor_address_text {
  font-size: 20px;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////// */
/* Reviews Section */
.review_container {
  padding-left: 24px;
}

.review_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.review_card_container {
  display: flex;
  gap: 24px;
  overflow-y: scroll;
}

.review_card_box {
  padding: 6px;
}

.review_cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 379px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 2px 2px 4px rgba(33, 158, 188, 0.08),
    -2px -2px 4px rgba(33, 158, 188, 0.08);

  font-size: 20px;
}

.reviewver {
  color: #023047;
  font-weight: 500;
}

.reviewver_box {
  display: flex;
  gap: 24px;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////// */

.doc_intro_textarea {
  width: 100%;
  border: 1px solid #989898;
  border-radius: 6px;
  height: 135px;
  padding: 16px 12px 36px 12px;
  line-height: 1.4;
  overflow: hidden;
  resize: none;
  font-size: 20px;
  font-family: inherit;
}

.doc_role_input {
  border: 1px solid #989898;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 24px;

  font-family: inherit;
}

.doc_heading_input {
  border: 1px solid #989898;
  border-radius: 6px;
  padding: 4px 8px;
  color: #023047;
  font-size: 32px;
  font-weight: 600;
  font-family: inherit;
  line-height: 40px;
  /* outline-color: blue; */
}

.doctor_exp_text {
  font-weight: 600 !important;
}

.doctor_exp_text_input {
  width: 105px;
  height: auto;
  background-color: #d5f3f8;
  border: 0.5px solid #023047;
  border-radius: 6px;
  padding: 0 4px;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.doctor_clinic_input {
  color: #1e7e9c;
  font-size: 20px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 6px;
  border: 0.5px solid #000;
}

.doctor_address_text_input {
  padding: 2px 8px;
  border-radius: 6px;
  font-size: 20px;
  border: 0.5px solid #000;
}

.doc_plus_icon {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.add_skill_text span {
  font-size: 18.5px;
}