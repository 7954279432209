.past-container {
  width: 300px;
  height: 100%;
  background-color: #fcf8f8;
  overflow-x: hidden;
  border-right: 1px solid #989898;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}

.past-container::-webkit-scrollbar {
  display: none;
}

.card-main_pastAppontment {
  margin: 20px 0 20px 20px;
}

.past-appointment_heading {
  margin-top: 18px;
  margin-left: 22px;
  color: #023047;
}

.blood .ul .red {
  color: #e2515e;
  text-align: right;
}

h1,
select {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.past-container .records-dropdown_past {
  margin-left: 20px;
  margin-top: 8px;
  position: relative;
  z-index: 10;
  width: 262px;
}

.card-main {
  height: auto;
  gap: 24px;
}

.card-one {
  width: 262px;
  height: auto;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: #ffffff;
  border-style: none;
  margin-top: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-one_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-1 {
  display: flex;
  justify-content: left;
}

.flex {
  display: flex;
  flex-direction: row;
}

.jun {
  padding: 5px 10px;
  border-radius: 16px;
  background-color: #ffb703;
}

.may-1 {
  padding: 5px 10px;
  border-radius: 16px;
  background: #d5f3f8;
  color: black;
}

.june {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0 0 0;
}

/* li {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  list-style: none;
} */

/* .blood .ul {
  height: 72px;
  width: 100%;
  color: black;
  text-align: right;
} */

/* .blood .ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.card-2 {
  width: 262px;
  height: 195px;
  margin-top: 8px;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: #ffffff;
  margin-top: 16px;
}

.li-card-2 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.linkRec.records,
.linkRec.records * {
  text-decoration: none;
}

.card-3 {
  width: 262px;
  height: 195px;
  margin-top: 8px;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: #ffffff;
  margin-top: 16px;
  margin-bottom: 12px;
}

.main-heading {
  color: #2f7ea5;
  text-decoration: none;
}

.linkRec.active {
  width: 262px;
  height: 195px;
  border-radius: 16px;
  background-color: #fff5d3;
  border-style: none;
  text-decoration: none;
  color: #023047;
}

.linkRec.active.june {
  color: #023047;
  background-color: #ffb703;
}

.active-card {
  background-color: #fff5d3;
  text-decoration: none;
}

.diagnosis-main-container {
  display: flex;
  /* gap: 10px; */
  margin: 0px 5px;
}


.capsule {
  font-size: 14px;
  font-weight: 500;
  color: #023047;
  text-transform: uppercase;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #FFB703;
  margin-top: 10px;

}

.type-of_records {
  font-size: 22px;
  font-weight: 700;
  color: #023047;
  padding: 10px 5px;
}

.doctor_notes-text {
  margin: 0px;
}

.doctor-text {
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  color: #023047;
  text-decoration: none;
}