/* Overlay page styles */
.test-overlay_page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main container styles */
.test-main-container {
  height: 650px;
  width: 90%;
  max-width: 1400px;
  border-radius: 6px;
  background-color: #fffefe;
  overflow: hidden;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  padding: 20px;
}

.test-main-container::-webkit-scrollbar {
  display: none;
}

/* Heading styles */
.test-main-container-heading {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  margin: 0 25px 20px 25px;
}

/* Search input container styles */
.test-input-type-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 25px 20px 25px;
  width: calc(100% - 50px);
}

/* Search container styles */
.test-search-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 600px;
}

.test-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}

.test-input-type-align {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding-left: 50px;
  outline: none;
}

/* Scrollable table section styles */
.test-table-wrapper {
  height: calc(100% - 200px);
  overflow-y: auto;
  margin: 0 25px 20px 25px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  */
  border-radius: 6px;
}
.test-table-wrapper::-webkit-scrollbar{
  display: none;
}
.test-table-section {
  overflow-x: auto;
}

.test-table {
  width: 100%;
  border-collapse: collapse;
}

.test-table th,
.test-table td {
  border: 1px solid rgb(214, 214, 214);
  padding: 8px;
  text-align: center;
}

.test-table-content {
  width: 100% !important;
}

.test-tableheader {
  background-color: #3CB9D4;
}

.test-header_text {
  padding: 10px 20px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.test-table_el {
  text-align: center;
  font-size: 15px;
}

.test-table_el:first-child,
.test-table_el:last-child {
  width: 50px; 
}

.test-no_tests_selected {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: rgb(189, 189, 189);
}

/* Bottom button styles */
.test-bottom-button-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.test-bottom-button-left,
.test-bottom-button-right {
  height: 40px;
  padding: 0 20px;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  width: auto;
  margin: 5px;
}

.test-bottom-button-left {
  background-color: #66d348;
}

.test-bottom-button-right {
  background-color: rgb(234, 36, 36);
}

.test-delete_icon_doctor {
  height: auto;
  cursor: pointer;
  border: none;
  outline: none;
}

.test-delete-icon-addmedicine {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 50px!important;
}
.delete-test-icon{
  width: 3.5rem!important;
}
