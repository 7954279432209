.profile-bg {
  position: fixed;
  z-index: 1;
  right: 68px;
  top: 68px;
  width: auto;
  height: auto;
  overflow: auto;
  background-color: #f8fbfc;
  border-radius: 12px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px;
}

.profile_link,
.text-align label {
  display: flex;
  padding: 10px 15px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  list-style-type: none;
  font-family: "Maven Pro", sans-serif;
}

.profile_link:hover,
.text-align label:hover {
  background-color: #ebcd47c9;
  border-radius: 10px;
  padding: auto;
  cursor: pointer;
  padding: 10px 15px;
}

.profile_link {
  font-size: 16px;
  text-decoration: none;
}

.profile_link:visited {
  color: black;
}