.left_section::-webkit-scrollbar {
  display: none;
}

.dashboadr_container {
  height: auto;
  display: flex;
  flex-direction: row;
}

.left_section {
  width: 300px;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff5d3;
  border-right: 1px solid #989898;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}

.top {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
}

.top > img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.top > h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

/* GENERAL INFO */

.title-tag {
  font-size: 16px;
  font-weight: 500;
  color: #023047;
  border-radius: 0px 12px 12px 0px;
  padding: 5px 32px;
  background-color: #ffe8a5;
}

.info {
  margin: 15px 0px;
}

.filled {
  font-size: 14px;
  font-weight: 400;
  color: #525252;
}

.left_section th,
.left_section td {
  padding: 2px;
  border: none;
  padding-left: 30px;
}

/* MODICAL CONDITION */
.medical-condition_container,
.ongingmedication-container {
  padding: 15px 0px 10px 30px;
}

.medical-input,
.allergies-input,
.ongoing-input {
  margin-bottom: 10px;
}

/* HEREDITARY CONDITIONS */
.heredity-input-div {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 15px 0px 10px 30px;
  width: 80%;
}

.heredity-input {
  width: auto;
  height: 40px;
  background-color: #ffb703;
  border-radius: 25px;
  padding: 7px 15px;
  margin: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 2px #d0cec7;
}

.sidebar-data-not-available {
  margin: 20px 0px 20px 30px;
  color: gray;
}
.sidebar-data-not-available-heredity,
.sidebar-data-not-available-onging,
.sidebar-data-not-available-medical {
  margin: 0px 0px 10px 0px;
  color: gray;
}

@media only screen and (max-width: 1024px) {

  .left_section {
    width: 200px;
  }

  .top {
    padding: 10px 0px;
  }

  .top > img {
    width: 90px;
    height: 90px;
  }

  .top > h3 {
    font-size: 15px;
  }

  .title-tag {
    font-size: 11px;
  }

  .info {
    width: 140px;
  }

  .filled {
    font-size: 10px;
  }

  .input {
    font-size: 12px;
  }
}
