.appointment-popup_container::-webkit-scrollbar {
  display: none;
}

.appointment-popup_container-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment-popup_container {
  position: fixed;
  top: 0%;
  left: -15%;
  background-color: #fff;
  z-index: 999;
  border-radius: 16px;
  padding: 30px;
  height: 103%;
  width: 800px !important;
  overflow-y: scroll;
  /* max-width: 900px; */
}

.appointment-popup {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
}

.time-slot-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.time-slot-card {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 26px;
  text-align: center;
  cursor: pointer;
}

.time-slot-card.selected {
  background-color: #219EBC;
  color: white;
}

.selected-appointment_heading {
  text-align: center;
  font-size: 25px;
  margin-top: -25px;
}

.date-section_left {
  width: 50%;
  text-align: center;
}

.time-slot-main {
  width: 100%;
}

.date-section_left>h3,
.time-slot-main>h3 {
  margin: 0 0 15px 0;
  text-align: center;
}

.no-time-slots-message {
  color: #aeaeae;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-top: 80px;
}

.select-slot_input-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
}

.slot-booking_basic-multi-select {
  width: 50%;
}

.slot-booking-input-text {
  width: 50%;
  outline: none;
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 4px;
}

.set-appointment-button-container {
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}

.set-appointment-button {
  height: 45px;
  width: 80%;
  margin: 10px;
  background-color: #219EBC;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 300;
  cursor: pointer;
}

.close-appointment-button {
  height: 45px;
  width: 50%;
  margin: 10px;
  background-color: #ff3232;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  cursor: pointer;

}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  margin: 0.166rem;
  background-color: #DAF7D0;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.react-datepicker__month-container {
  height: auto !important;
  width: 300px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  visibility: hidden;
}

.react-datepicker {
  font-size: 0.8rem;
  color: #000;
  border-radius: 4px;
  display: inline-block;
  line-height: initial;
  position: relative;
  left: 50%;
  transform: translatex(-50%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.react-datepicker__day-name {
  background-color: #219EBC;
  margin-top: 10px;
}

/* Change the color of the selected date */
.react-datepicker__day--selected {
  background-color: #FF9F0A;
  /* Your custom color */
  color: white;
  /* Text color */
}

/* Change the color of the current date */
.react-datepicker__day--today {
  background-color: #219EBC;
  /* Your custom color */
  color: white;
  /* Text color */
}

/* Optional: Add hover effect on selected date */
.react-datepicker__day--selected:hover {
  background-color: #FF9F0A;
  /* Your custom hover color */
}

/* Optional: Add hover effect on today's date */
.react-datepicker__day--today:hover {
  background-color: #219EBC;
  /* Your custom hover color */
}

/* ?LOADER? */

.popup-overlay_select-slot-page {
  position: fixed;
  top: -20;
  left: -20;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content_select-slot-page {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}