.slot-main_container::-webkit-scrollbar {
    display: none;
}

.slot-overlay-page_container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slot-main_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    width: 40%;
    max-width: 1400px;
    border-radius: 6px;
    background-color: #fffefe;
    position: fixed;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    padding: 20px;
    overflow-y: scroll;
}

.close-action {
    position: absolute;
    right: 10px;
    top: 10px;
}

.slot-title {
    font-size: 25px;
    margin-bottom: 10px;
}

.time-slot_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
}

.time-slot_container>input[type="time"] {
    flex: 1;
    outline: none;
    font-size: 19px;
    border-radius: 6px;
    padding: 0.3rem 0 0.3rem 0.8rem;
    border: 1px solid rgb(202, 202, 202);
    box-sizing: border-box;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.time-input_container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.slot-title-count {
    font-size: 15px;
    font-weight: 500;
}

.button_container-addslot {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.add-slot-remove-time-slot-button,
.add-button-new {
    width: 25%;
    text-align: center;
    background-color: #E2515E;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    margin: 20px 0px;
}

.add-button-new{
    background-color: #4868F6;
}

.add-button-save {
    border-radius: 6px;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    background-color: #66D348;
    margin-top: 20px;
}