.main-calendar {
  width: 303px;
  height: 100%;
  border: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid rgb(172, 172, 172);
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  overflow-y: scroll;
  z-index: 0;

}

.main-calendar .main-calendar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.main-calendar .headingh1 {
  font-size: 32px;
  margin: 20px 0 20px 20px;
}

.main-calendar .main-calendar-section .calendar-cards {
  width: 270px;
  height: 124px;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-decoration: none;
}

.main-calendar .main-calendar-section .calendar-cards .img-h1 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.calendar-cards .img-h1 .card-text {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.calendar-cards .bottom-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-cards .bottom-text .bottom-text-h {
  width: 107px;
  height: 28px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.calendar-cards .bottom-text .bottom-time {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.new-bg {
  background-color: #FFD56D;
}

.regular-bg {
  background-color: #4868F6;
}

.critical-bg {
  background-color: #e2515e;
}

.published-bg-non {
  border: 1px solid #219EBC;
  color: black;
}

/* text color */
.new-calendar {
  color: black !important;
}

.regular-calendar,
.critical-calendar {
  color: white !important;
}

.published-calendar {
  color: #219ebc !important;
}

.no-data-calebdar-sidebar {
  font-size: 20px;
  font-weight: 500;
  color: gray;
  margin-top: 40%;
}