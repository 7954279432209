/* Hide scrollbar */
::-webkit-scrollbar {
  display: none;
}

* {
  outline: none;
  font-family: Inter, sans-serif;
}

/* Overlay styling */
.patient-form-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}

/* Form container styling */
.patient-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 16px;
  padding: 40px;
  height: 670px;
  width: 800px;
  z-index: 10001;
  overflow-y: scroll;
}

/* Cross icon styling */
.patient-form-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #000;
}

/* General form styles */
.patient-form-title {
  margin-bottom: 20px;
}

.patient-form-label {
  display: block;
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
}

.patient-form-gender-text,
.patient-form-blood-group-text {
  margin: 0 0 0 5px;
  font-size: 15px;
}

.patient-form-input,
.patient-form-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 42px;
}

.patient-form-bloodgroup {
  font-size: 16px;
  font-weight: 400;
  width: 355px;
  margin-bottom: 20px;
  margin-left: -1px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 42px;
  padding: 10px;
}

.patient-form-select-country-code {
  width: 50%;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-weight: 400;
  font-family: inter;
  height: 42px;
  padding: 10px;
}

.css-13cymwt-control {
  border: 1px solid #ccc;
  height: 42px;
  margin-bottom: 20px !important;
}

.patient-form-select .react-select__control {
  height: 42px;
  border-radius: 5px;
  box-sizing: border-box;
}

.patient-form-select .react-select__menu {
  z-index: 10001;
}

.patient-form-select .react-select__single-value,
.patient-form-select .react-select__multi-value__label {
  font-size: 15px;
  padding-top: 4px;
}

/* Flex group styles for form fields */
.patient-form-flex-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Addictive habits section */
.patient-form-addictive-habits {
  margin: 20px 0;
}

.patient-form-habit-select {
  display: flex;
  align-items: center;
  gap: 15px;
}

.patient-form-habit-icon {
  height: 45px;
  width: 45px;
  margin: 0 -13px 12px 0;
}

.patient-form-habit-icon-cigarrete {
  height: 20px;
  width: 42px;
  margin: 0 0 18px 0;
}

.patient-form-habit-addictive {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 42px;
}

/* Button group */

.patient-form-button {
  width: 100%;
  padding: 15px 0px;
  border-radius: 6px;
  background-color: #219EBC;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.patient-form-submit {
  background-color: #66d348;
  color: white;
}

.patient-form-close {
  background-color: rgb(234, 36, 36);
  color: white;
}

/* Make Add Patient button full width */
.patient-form-submit-full {
  width: 100%;
}
