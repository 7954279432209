*{
font-family: inter;
}

.doctor_clinic-main_container::-webkit-scrollbar {
    display: none;
}

.doctor_clinic-main_container {
    background-color: #fff;
    top: 88px;
    left: 112px;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 32px;
    overflow-y: auto;
    text-align: left;
}

.doctorClinicContainer {
    display: flex;
    justify-content: space-between;
}

.doctorClinicBackLink,
.doctorClinic-add-new_clinic-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    height: 40px;
    margin-bottom: 10px;
    background-color: #66D348;
    padding: 7px 30px;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.doctorClinic-add-new_clinic-button {
    background-color: #FFD56D;
    color: black;
    font-size: 19px;
    font-weight: 500;
    gap: 5px;
    cursor: pointer;
}

.doctorClinic-add-new_clinic-button .addIconNewClinic {
    font-size: 30px;
}

.doctor_clinic_main_heading{
    margin: 10px 0 20px 0;
    font-size: 28px;
    font-weight: 600;
}
.doctor_clinic_img_container>img{
    height: 400px;
}

.clinic-card_main-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 22px;
    margin-top: 30px;
}

.clinic-card_main-container .clinic-card-container {
    display: flex;
    gap: 20px;
    width: 480px;
    height: 180px;
    border-radius: 8px;
    background-color: #e8f5ff;
    text-decoration: none;
    color: black;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.clinic-card-container .left-box img {
    width: 200px;
    height: 180px;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.clinic-card-container .right-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px 10px 0px;
}

.right-box .clinic-name {
    font-size: 22px;
    font-weight: 600;
}

.right-box .doctor-name {
    font-size: 18px;
    font-weight: 500;
}

.right-box .location-section {
    display: flex;
    align-items: center;
    margin-left: -4px;
    margin-top: 10px;
}

.right-box .location-section .location-icon {
    color: #0407a1;
}

.right-box .location-section .clinic_location {
    color: #0407a1;
    font-size: 14px;
}

.right-box .rating-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right-box .rating-section .star_icon {
    color: #ffb700;
}

.right-box .rating-section .rating-count {
    font-size: 14px;
    margin-left: 5px;
}