.history-details {
  margin: 0;
  padding: 0;
  padding: 30px;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
}

.history-details::-webkit-scrollbar {
  display: none;
}

.history-details .history-dates {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.history-cards {
  display: flex;
  height: auto;
  flex-wrap: wrap;
  gap: 20px;
}

.card_container {
  min-width: 280px;
  height: 216px;
  border-radius: 10px;
  background-color: #ffffff;
  text-decoration: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.card_container .icon {
  gap: 10px;
  display: flex;
  margin-bottom: 10px;
}

.icon-logo {
  width: 30px;
  height: 30px;
}

.card_container .card-detail h4 {
  font-size: 20px;
  padding: 15px 0 5px 0;
  color: #023047;
  text-decoration: none;
}

.inner-card {
  padding: 20px 10px;
  width: 100%;
  height: auto;
}

.card_container .icon .date-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #baebf4;
  border-radius: 26px;
  height: 170%;
  width: 30%;
}

.card_container .icon .date-tag span {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.inner-card .icon {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card_container .card-detail .card-title {
  display: flex;
  justify-content: space-between;
}

.card_container .card-detail .card-title h3 {
  padding: 2px 0px;
  font-size: 16px;
  color: black;
}

.card_container .card-detail .card-title .detail p {
  padding: 2px 0px;
  color: black;
}

.card_container .card-detail .card-title p {
  padding: 6px 0px;
}

.card_container .card-detail .card-title .ul .detail1 {
  padding: 2px 0px;
  text-align: right;
  color: red;
}

.card_container .card-detail .card-title .ul {
  width: 100%;
  color: black;
  text-align: right;
}

.card_container .card-detail .card-title .ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataNotAvil {
  width: 100%;
  text-align: center;
  margin-top: 15%;
  font-size: 25px;
  font-weight: 500;
  color: gray;
}
.background-change{
  background-color: #ffffff00;
  border: none;
}