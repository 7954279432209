* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.twofactor_input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.twofactor_input::-webkit-inner-spin-button,
.twofactor_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.popup_two_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_cross {
  width: 24px;
  height: 24px;
  background-color: none;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.popup_two_background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}

.twofactor_content {
  background-color: #f6f6f6;
  z-index: 999;
  border-radius: 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 714px;
  height: 534px;
  position: relative;
}

.section_two_1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 58px;
}

.twofactor_text {
  width: 541px;
  height: 128px;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  color: #000;
  margin-bottom: 64px;
}

.twofactor_input_box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 541px;
  margin-bottom: 100px;
}
.twofactor_heading {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.twofactor_input {
  border: 1px solid #989898;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  padding: 12px 36px 12px 16px;
}

.btn_twofactor {
  align-self: flex-start;
  margin-left: 86px;
  margin-bottom: 48px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #4868f6;
  padding: 12px 40px;
  border-radius: 6px;
  margin-bottom: 64px;
  cursor: pointer;
}
