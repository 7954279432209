.calendar_container {
  background-color: #f8fbfc;
  width: auto;
  height: auto;
  padding: 32px;
  position: fixed;
  top: 88px;
  left: 112px;
  right: 20px;
  bottom: 10px;
  border-radius: 32px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.calendar_container .calendar-custom {
  width: auto;
  height: auto;
  position: fixed;
  right: 0px;
  bottom: 0px;
  margin-left: 300px;
  background-color: #efefef;
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}
