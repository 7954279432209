.medicards_icontext {
  font-size: 16px;
  line-height: 20px;
}
.medicard_icon {
  height: 32px;
  align-self: stretch;
}

.medicards_iconbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d5f3f8;
  color: #023047;
  border-radius: 16px;
  padding: 6px 16px;
}

/* OTHER CARDS */

.medicards_groups {
  background-color: #fff;
  border-radius: 16px;
  width: 280px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    2px 4px 4px rgba(33, 158, 188, 0.08), -2px -2px 4px rgba(33, 158, 188, 0.08);
  cursor: pointer;
}
.medicards_content {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  line-height: 20px;
  width: 232px;
}

.medicards_heading {
  font-size: 20px;
  color: #023047;
  font-weight: 500;
}

.medicards_text {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.medi_left_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 8px;
}

.medicards_groups.active_card {
  background-color: #fff5d3;
 
}
.medicards_iconbox.active_card {
  align-items: center;
  justify-content: center;
  background-color: #ffb703;
  color: #023047;
  border-radius: 16px;
  padding: 6px 16px;
}

.medicards_groups:link {
  text-decoration: none;
}
