.signup-hello-agin_sign {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 20px;
}

.signup-input-section_sign {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 15px; 
  align-items: center;
}

.signup-input-section_sign .signup-select-container {
  width: 25%; 
  outline: none;
  padding: 12px;
  font-size: 19px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgb(202, 202, 202);
}

.signup-input-section_sign>input {
  flex: 1;
  outline: none;
  padding: 12px;
  font-size: 19px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgb(202, 202, 202);
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.signup-input-section_sign>input:hover {
  border-color: #3cb9d4;
  box-shadow: 0 0 5px rgba(60, 185, 212, 0.5);
}

.signup-optionCountery {
  font-size: 14px;
  background-color: #fff !important;
}

.signup-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-login_container {
  height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 45px;
  padding: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  overflow: hidden;
}

/* ============= LEFT CONTAINER STYLES ================ */

.signup-left_container-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 40px 100px;
  border-radius: 30px;
  background-color: #1f657f;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  box-sizing: border-box;
}

.signup-left_container-section>h1 {
  font-size: 50px;
  line-height: 60px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  font-family: "Maven Pro", sans-serif;
  margin-bottom: 20px;
}

.signup-left_container-section>img {
  max-width: 100%;
  height: auto;
}

/* ============= RIGHT CONTAINER STYLES ================ */
.signup-right_container-section {
  width: 50%;
  padding: 50px 100px;
  box-sizing: border-box;
  overflow-y: auto;
}

.signup-right_container-section::-webkit-scrollbar {
  display: none;
}

.signup-right_container-section .signup-hello-agin {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 20px;
}

.signup-right_container-section .signup-input-section {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 25px;
}

.signup-input-section>input{
  width: 100%;
  outline: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgb(202, 202, 202);
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.signup-input-section>select, .signup-dob-input, .signup-file-input {
  width: 100%;
  outline: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgb(202, 202, 202);
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.signup-input-section>input:hover,
.signup-input-section>select:hover {
  border-color: #3cb9d4;
  box-shadow: 0 0 5px rgba(60, 185, 212, 0.5);
}

.signup-forgot-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 20px 0;
  font-family: "Maven Pro", sans-serif;
}

.signup-remember-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2px;
  font-family: "Maven Pro", sans-serif;
}

.signup-remember-checkbox>input {
  margin-top: 5px;
  outline: none;
}

.signup-login_footer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 25px;
}

.signup-login_footer .signup-login-button {
  border: none;
  padding: 15px 0px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #3cb9d4;
  outline: none;
  font-family: "Maven Pro", sans-serif;
  cursor: pointer;
}

.signup-dont-have-account {
  margin-top: 30px;
  font-size: 18px;
  font-family: "Maven Pro", sans-serif;
}

.signup-isLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
}

.signup-error-container {
  height: 4%;
}

.signup-error-message {
  color: red;
  font-size: 12px;
  font-family: "Maven Pro", sans-serif;
}

@media (max-width: 1024px) {
  .signup-login_container {
    flex-direction: column;
    height: auto;
    width: 90vw;
  }

  .signup-left_container-section,
  .signup-right_container-section {
    width: 100%;
    padding: 20px;
  }

  .signup-left_container-section {
    padding: 20px 50px;
  }

  .signup-right_container-section {
    padding: 20px 50px;
  }

  .signup-left_container-section>h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .signup-right_container-section .signup-hello-agin {
    font-size: 30px;
    line-height: 40px;
  }

  .signup-input-section>input,
  .signup-input-section>select {
    padding: 10px 5px;
  }

  .signup-login_footer .signup-login-button {
    padding: 10px 0px;
    font-size: 18px;
  }

  .signup-dont-have-account {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .signup-left_container-section {
    padding: 20px;
  }

  .signup-right_container-section {
    padding: 20px;
  }

  .signup-left_container-section>h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .signup-right_container-section .signup-hello-agin {
    font-size: 25px;
    line-height: 35px;
  }

  .signup-input-section>input,
  .signup-input-section>select {
    padding: 8px 5px;
  }

  .signup-login_footer .signup-login-button {
    padding: 8px 0px;
    font-size: 16px;
  }

  .signup-dont-have-account {
    font-size: 14px;
  }
}

/* Fullscreen loader styles */
.signup-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Blurred background when loader is active */
.signup-blurred {
  filter: blur(2px);
  pointer-events: none;
}

.signup-otp-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.signup-otp-input-section {
  width: 12%;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  text-align: center;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.signup-otp-input-section:hover {
  border-color: #3cb9d4;
  box-shadow: 0 0 5px rgba(60, 185, 212, 0.5);
}

