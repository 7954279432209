/* DoctorNotes.css */
.doctor-cards::-webkit-scrollbar,
.doctor-notes::-webkit-scrollbar {
  display: none;
}

.doctor-notes {
  height: 100%;
  margin: 0;
  background-color: #f8fbfc;
  overflow: auto;
}

/* TOGGLE DESIGN START -------------------- */

.doctor-notes .container_toggle-main {
  display: flex;
  flex-direction: row-reverse;
}

.container_toggle-main .toggle-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid gray;
  margin: 32px;
  border-radius: 6px;
}

.container_toggle-main .toggle-box:hover {
  cursor: pointer;
}

.active,
.doctor-cards.doctor-card-text.active {
  background-color: #ffd56d;
}

.toggle-box .Past-records {
  padding: 10px 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.toggle-box .Patient-info {
  padding: 10px 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* TOGGLE DESIGN END ----------------------- */

.doctor-notes-column-1,
.doctor-notes-column-2 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.cards-section {
  width: 1000px;
  height: 230px;
  margin: 0 15px 30px 20px;
  display: flex;
  flex-direction: column;
}

.doctor-cards {
  flex: 1;
  background-color: #ffffff;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
  border: 1px solid #989898;
  padding: 10px;
}

.doctor-cards .react-select-container {
  margin-bottom: 10px;
}

.doctor-cards .react-select-container {
  margin-bottom: 10px;
}

.doctor-cards>input {
  width: 100%;
  outline: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
}

.cards-heading-test,
.cards-heading-medicine {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0 20px 23px;
}

.doctor-cards:focus {
  background-color: #ffe8a5;
}

.text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
}

.medi-table-container-notes {
  margin: 0 20px;
  display: flex;
  overflow-y: auto;
  margin: 0 25px 20px 25px;
  border-radius: 6px;
}

.medi-table-container-notes::-webkit-scrollbar {
  display: none;
}

.medi-table-content {
  border-radius: 6px;
  width: 100% !important;
  height: fit-content;
  overflow: hidden;
}

.medi-table {
  border-bottom: 1px solid #dcdcdc;
  border-collapse: collapse;
  width: 100%;
}

.medi-table th,
.medi-table td {
  border: 1px solid rgb(214, 214, 214);
  padding: 8px;
  text-align: left;
}

.medi-table-header {
  background-color: #3CB9D4;
}

.medi-table-body td {
  border-bottom: 1px solid #dcdcdc;
}

.medi-table-body:last-child td {
  border-bottom: none;
}

.medi-header-text {
  padding: 10px 20px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.medi-table-el {
  text-align: center;
  padding: 4px 8px;
  flex-direction: column;
  font-size: 15px;
  position: relative;
}

.medi-icon-box {
  display: flex;
  flex-direction: column;
  height: 240px;
  margin-top: 52px;
  align-items: flex-end;
}

.medi-delete-icon {
  height: auto;
  cursor: pointer;
  border: none;
  outline: none;
}

.medi-no-medicine-selected {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: rgb(189, 189, 189);
}

.medi-delete-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.div-btn-down {
  display: flex;
  justify-content: flex-end;
  margin: 10px 32px 30px 0;
}

.no-medicine-selected {
  font-size: 20px;
  padding: 8px 0;
  text-align: center;
}

.medi-icon-box {
  width: 30px;
  margin: 0 0 0 10px;
}

.cards-container {
  flex: 1;
  height: 170px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 10px 0;
}

.react-select__control .css-13cymwt-control {
  height: 50px !important;
}

.cards-container::-webkit-scrollbar {
  display: none
}

.symptom-card,
.diagnosis-card,
.remember-card {
  width: auto;
  height: 40px;
  background-color: #ffb703;
  border-radius: 5px;
  padding: 7px 12px;
  margin: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 2px #d0cec7;
}

.appointment-card {
  width: auto;
  height: auto;
  background-color: #ffb703;
  border-radius: 5px;
  padding: 0px 12px;
  margin: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 2px #d0cec7;
}

.card-remove {
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: red;
}

/* PRESCRIPTION BUTTON */
.down-btn {
  padding: 15px 40px;
  border-radius: 6px;
  background-color: #66d348;
  color: #f8fbfc;
  margin: 20px -10px 0 0;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.down-btn:hover {
  background-color: #4de224;
  color: #feffff;
}

/* Doctor Notes Tests Table */
.test-table-container-notes {
  margin: 0 20px;
  display: flex;
  overflow-y: auto;
  margin: 0 25px 20px 25px;
  border-radius: 6px;
}

.test-table-container-notes::-webkit-scrollbar {
  display: none;
}

.test-table-content-notes {
  border-radius: 6px;
  width: 100% !important;
  height: fit-content;
  overflow: hidden;
}

.test-table {
  border-bottom: 1px solid #dcdcdc;
  border-collapse: collapse;
  width: 100%;
}

.test-table th,
.test-table td {
  border: 1px solid rgb(214, 214, 214);
  padding: 8px;
  text-align: left;
}

.test-thead-lab {
  background-color: #3CB9D4;
}

.test-table-body td {
  border-bottom: 1px solid #dcdcdc;
}

.test-table-body:last-child td {
  border-bottom: none;
}

.test-header-text {
  padding: 10px 20px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.test-table-el {
  text-align: center;
  padding: 4px 8px;
  flex-direction: column;
  font-size: 15px;
  position: relative;
}

.test-delete-icon {
  height: auto;
  cursor: pointer;
  border: none;
  outline: none;
}

.test-no-tests-selected {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: rgb(189, 189, 189);
}

.test-no-tests-selected {
  font-size: 20px;
  padding: 8px 0;
  text-align: center;
}

.input-main-container {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 0 20px;
}

.input-main-container div {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.input-main-container-text {
  height: 42px;
  padding: 10px;
  border-radius: 6px;
  margin: 8px 0 20px 0;
  border: 1px solid #989898;
  font-size: 16px;
}

.input-main-container label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #3CB9D4;
}