* {
  padding: 0;
  margin: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #215569;
}
