* {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.App {
  height: 100vh;
  width: auto;
  background-color: #023047;
}