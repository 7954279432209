.side_bar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 112px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto; /* Allow vertical scrolling */
  scrollbar-width: thin; /* Firefox scrollbar */
}

.side_bar-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.side_bar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15%;
  justify-content: space-around;
}

.linkImg {
  padding: 25px 0px;
}

.imageLogo {
  width: 64px;
  height: 64px;
}

.side_items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.side_items .navLinks-container {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #023047;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 10,
    'wght' 500,
    'GRAD' 0,
    'opsz' 30
}

.navLinks-container>span {
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  font-size: 40px;
}
.navLinks-container.active .title,
.navLinks-container.active>span {
  color: #FFB703;
}

.title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .imageLogo {
    width: 54px;
    height: 54px;
  }

  .image {
    width: 24px;
    height: 24px;
  }

  .title {
    color: #ffffff;
    font-size: 12px;
  }
}

/* Customize scrollbar for webkit browsers (Chrome, Safari) */
.side_bar-container::-webkit-scrollbar {
  width: 6px;
}

.side_bar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.side_bar-container::-webkit-scrollbar-thumb {
  background: #888;
}

.side_bar-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
