* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.main-visualization {
  padding: 50px 30px;
}
.main-visualization .alls-card {
  display: flex;
  justify-content: space-around;
}
.main-visualization .card-visual {
  height: 140px;
  width: 224px;
  border: none;
  border-radius: 8px;
}

.main-visualization .card-visual .card-title {
  padding: 9px;
}

.main-visualization .card-visual .img-icon {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 17px 10px;
}
.main-visualization .alls-card .card-title h3 {
  font-size: 18px;
  padding: 12px 10px;
  font-weight: 400;
  color: #023047;
}
.main-visualization .alls-card .img-icon .num p {
  color: #023047;
  font-size: 16px;
  font-weight: 500;
}
.main-visualization .card-visual-clr1 {
  background-color: #f2e3ff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr2 {
  background-color: #fed0e2;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr3 {
  background-color: #d5ffce;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr4 {
  background-color: #fff2c5;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr5 {
  background-color: #d9eafe;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr6 {
  background-color: #f1dfdf;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.hr {
  border-bottom: 1px solid #dcdcdc;
}
.main-schedule {
  padding: 40px 0px;
}

.main-schedule .schedule {
  display: flex;
  justify-content: space-around;
}

.main-schedule .schedule .section-condition {
  display: flex;
  justify-content: space-between;
  width: 340px;
  height: 48px;
  border-radius: 6px;
  padding: 0 10px;
}
