.main-background-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.main-background {
  height: 85%;
  width: 40%;
  border-radius: 6px;
  background-color: #fffefe;
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 50px;
}

.section1::-webkit-scrollbar {
  display: none;
}

.cross-icon_container {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.cross_icon-image {
  width: 30px;
  height: 30px;
}

.searchpop {
  display: flex;
  align-items: center;
  width: auto;
  gap: 15px;
  border-radius: 6px;
  padding: 5px 30px;
  margin: 20px 0px 30px 0px;
  border: 1px solid #bbcbe6;
}

.searchpop > input {
  border: none;
  width: 600px;
  padding: 9px;
  outline: none;
}

.search-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  width: auto;
  height: 80px;
  border-radius: 8px;
  margin-top: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  text-decoration: none;
}

.search-section-1 {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-left: 24px;
  width: auto;
  height: 48px;
}

.search-section-1 p {
  font-size: 20px;
  color: #000000;
  font-weight: 400;
}

.search-card .search-section-1 img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 100px;
  border: 1px solid #ffffff;
  background-position: center;
  background-repeat: no-repeat;
}

.search-btn .search-tag-button {
  width: 107px;
  height: 30px;
  border-radius: 16px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* getEventColor change button */
.new-bg-searchPop {
  background-color: #ffd56d;
}

.regular-bg-searchPop {
  background-color: #4868f6;
}

.critical-bg-searchPop {
  background-color: #e2515e;
}

.published-bg-searchPop {
  border: 1px solid #219ebc !important;
  background-color: white;
}

/* getTextColor text color change */
.new-searchPop {
  color: black;
}

.regular-searchPop,
.critical-searchPop {
  color: white;
}

.published-searchPop {
  color: #219ebc;
}

.search-add-patient-button-container {
  margin-top: 35px;
}

.search-add-patient-button {
  width: 100%;
  padding: 15px 0px;
  border-radius: 6px;
  background-color: #219EBC;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
