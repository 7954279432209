/* Overlay page styles */
.medicine-overlay_page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main container styles */
.medicine-main-container {
  height: 650px;
  width: 90%;
  max-width: 1400px;
  border-radius: 6px;
  background-color: #fffefe;
  overflow: hidden;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  padding: 20px;
}

.medicine-main-container::-webkit-scrollbar {
  display: none;
}

/* Heading styles */
.medicine-main-container-heading {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  margin: 0 25px 20px 25px;
}

/* Search input container styles */
.medicine-input-type-search {
  display: flex;
  justify-content: space-between;
  margin: 20px 25px 20px 25px;
  width: calc(100% - 50px);
}

/* Search container styles */
.medicine-search-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 600px;
}

.medicine-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}

.medicine-input-type-align {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding-left: 50px;
  outline: none;
}

.my-dropdown-control_groups {
  width: 600px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid #989898;
}

/* Scrollable table section styles */
.medicine-table-wrapper {
  height: calc(100% - 200px);
  overflow-y: auto;
  margin: 0 25px 20px 25px;
  border-radius: 6px;
}

.medicine-table-section {
  overflow-x: auto;
}

.medicine-medi_table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.medicine-medi_table th,
.medicine-medi_table td {
  border: 1px solid rgb(214, 214, 214);
  padding: 8px;
  text-align: left;
}

.medicine-medi-table-content {
  width: 100% !important;
}

.medicine-tableheader {
  background-color: #3CB9D4;
}

.medicine-header_text {
  padding: 10px 20px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.medicine-table_el {
  text-align: center;
  font-size: 15px;
}

.medicine-table-input {
  width: 100%;
  border: none;
  padding: 8px;
  text-align: center;
}

.medicine-table-input::placeholder {
  color: #888;
}

.medicine-table-input:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.medicine-no_medicine_selected {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: rgb(189, 189, 189);
}

/* Bottom button styles */
.medicine-bottom-button-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.medicine-bottom-button-left,
.medicine-bottom-button-right {
  height: 40px;
  padding: 0 20px;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  width: auto;
  margin: 5px;
}

.medicine-bottom-button-left {
  background-color: #66d348;
}

.medicine-bottom-button-right {
  background-color:  rgb(234, 36, 36);
}

.medicine-delete_icon_doctor {
  height: auto;
  cursor: pointer;
  border: none;
  outline: none;
}

.medicine-search-results {
  overflow-y: auto;
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 999;
  cursor: pointer;
  top: 50px;
  height: 200px;
  width: 100%;
}

.medicine-search-results::-webkit-scrollbar {
  display: none;
}
.medicine-table-wrapper::-webkit-scrollbar {
  display: none;
}

.medicine-brandsearch {
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
}

.medicine-brandsearch:hover {
  background-color: #beebf5;
}
