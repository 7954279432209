.quantity-dropdown-options::-webkit-scrollbar,
.medi_brands_dropdown::-webkit-scrollbar,
.medi_left_section::-webkit-scrollbar,
.medi_right_section::-webkit-scrollbar,
.medi_dropdown::-webkit-scrollbar,
.medi_left_container::-webkit-scrollbar {
  display: none;
}
.medi_left_container {
  background-color: #fff;
  top: 88px;
  left: 112px;
  right: 20px;
  bottom: 20px;
  width: auto;
  height: auto;
  overflow: scroll;
  position: absolute;
  box-sizing: border-box;
  border-radius: 32px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 332px;
  height: auto;
  overflow-x: hidden;
  border-right: 1px solid #989898;
  border-radius: 32px 0 0 32px;
}

.medi_content {
  display: flex;
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: space-between;
}

.medi_input_search {
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  height: 22px;
}

.medi_input_search::placeholder {
  color: #989898;
}

/* LEFT SECTION TOP */

.medi_left_section {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  width: auto;
  height: auto;
  height: 100%;
  /* position: relative; */
  padding: 32px 20px 0 32px;
  border-right: 1px solid #dcdcdc;
}

.medi_left_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.medi_delete_icon,
.medi_plus_icon,
.medi_search_icon {
  width: 48px;
  height: 48px;
}
.medi_plus_icon {
  cursor: pointer;
}

.medi_heading {
  color: #023047;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.medi_left_inputbox {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px 0 8px;
  width: 280px;
  border: 1px solid #989898;
  border-radius: 6px;
  color: #989898;
  position: relative;
}

.medi_search_input.active,
.medi_left_inputbox.active {
  border-bottom-color: transparent;
  border-radius: 6px 6px 0 0;
}

GENERAL STYLES
/* 
.medicards_groups {
  background-color: #fff5d3;
  border-radius: 16px;
  width: 280px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
} */

/* MAIN CARD */
/* .medicards_content {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  line-height: 20px;
  width: 232px;
} */

/* .medicards_heading {
  font-size: 20px;
  color: #023047;
  font-weight: 500;
}
.medicards_text {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.medicard_icon {
  height: 32px;
  align-self: stretch;
}

.medicards_iconbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb703;
  color: #023047;
  border-radius: 16px;
  padding: 6px 16px;
}
.medicards_icontext {
  font-size: 16px;
  line-height: 20px;
} */

/* OTHER CARDS */
/* .medicards_groups_1 {
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    2px 4px 4px rgba(33, 158, 188, 0.08), -2px -2px 4px rgba(33, 158, 188, 0.08);
  border-radius: 16px;
  width: 280px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  cursor: pointer;
}

.medicards_iconbox1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d5f3f8;
  color: #023047;
  border-radius: 16px;
  padding: 6px 16px;
}

.medi_left_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 8px;
} */
/* GENERAL STYLES */

/* MAIN CARD */

/* Icons */
.medicards_icontext {
  font-size: 16px;
  line-height: 20px;
}
.medicard_icon {
  height: 32px;
  align-self: stretch;
}

.medicards_iconbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d5f3f8;
  color: #023047;
  border-radius: 16px;
  padding: 6px 16px;
}

/* OTHER CARDS */

.medicards_groups {
  background-color: #fff;
  border-radius: 16px;
  width: 280px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    2px 4px 4px rgba(33, 158, 188, 0.08), -2px -2px 4px rgba(33, 158, 188, 0.08);
  cursor: pointer;
}
.medicards_content {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  line-height: 20px;
  width: 232px;
}

.medicards_heading {
  font-size: 20px;
  color: #023047;
  font-weight: 500;
}

.medicards_text {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.medi_left_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 8px;
}

.medicards_groups.active_card {
  background-color: #fff5d3;
  /* border-radius: 16px;
  width: 280px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
    2px 4px 4px rgba(33, 158, 188, 0.08), -2px -2px 4px rgba(33, 158, 188, 0.08);
  cursor: pointer; */
}
.medicards_iconbox.active_card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb703;
  color: #023047;
  border-radius: 16px;
  padding: 6px 16px;
}
/* .medicards_icon {
  background-color: #989898;
  display: flex;
  width: max-content;
} */
/* //////////////////////////////////////////////////////////////////// */
/* DROP DOWN */

.option_rec_box {
  background-color: #d5f3f8;
}

.medi_dropdown {
  color: #000;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: -1px;
  width: 280px;
  height: fit-content;
  z-index: 1;
  border: 1px solid #989898;
  border-top: none;
  border-radius: 0 0 6px 6px;
  outline: none;
  box-sizing: border-box;
  overflow: auto;
}

.medi_option {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 32px;
  /* height: 48px; */
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
}

.filtered_option {
  background-color: orangered;
}
.option_rec_box:hover,
.medi_option:hover {
  background-color: #dcdcdc;
  color: #023047;
}

.option_rec_box:hover .rec_text {
  color: #fff;
  background-color: #989898;
}

.option_rec_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}

.option_rec_box .medi_option {
  border-bottom: none;
}
.medi_option:last-child {
  border-bottom: none;
}

.rec_text {
  color: #fff;
  background-color: #3cb9d4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
}

.medi_brands_dropdown {
  color: #000;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: -1px;
  width: 300px;
  height: fit-content;
  z-index: 1;
  border: 1px solid #989898;
  border-top: none;
  border-radius: 0 0 6px 6px;
  outline: none;
  box-sizing: border-box;
  overflow: auto;
}
