.header_container {
  position: fixed;
  top: 0;
  right: 0;
  left: 112px;
  Height: 88px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.profile-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  position: absolute;
  right: 30px;
  cursor: pointer;
  width: auto;
}

.profile-section .drImage-profile {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  border: 1px solid #023047;
  background-size: contain;
  z-index: 000 !important;
}

.dr-title h5 {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}

.dr-title marquee {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.downArrowIcon {
  color: #ffffff;
  cursor: pointer;
  font-size: 35px !important;
}

@media only screen and (max-width: 1024px) {
  .header_container {
    height: 65px;
  }

  .profile {
    height: 46px;
    width: 46px;
  }

  .dr-title h5 {
    font-size: 18px;
  }

  .dr-title span {
    font-size: 12px;
  }

  .down-button {
    height: 8px;
    width: 18px;
  }
}