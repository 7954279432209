.card-container-main::-webkit-scrollbar {
  display: none;
}

.card-container-main {
  height: 100%;
  margin: 0;
  background-color: #f8fbfc;
  overflow: auto;
}

.card-container-records {
  display: flex;
  justify-content: space-around;
  width: auto;
  height: auto;
}

.cards-section-pre {
  width: 1000px;
  height: 230px;
  margin: 0 15px 30px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgb(125, 125, 125);

}

.cards-container-tag>h2 {
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
}

.prescription-para {
  font-size: 18px;
  padding: 10px;
}

.card-records {
  height: auto;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0.7rem;
  border-radius: 16px;
  border: none;
  cursor: pointer;

}

.cards-heading-certificate {
  margin: 20px 0 20px 20px;
}

.card-heading-1 {
  margin: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #ad2339;
}

.card-heading-2 {
  font-family: Inter;
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #fb8500;
}

.card-heading-3 {
  font-family: Inter;
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #29701b;
}

.card-records-one {
  background-color: #daf7d0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.one {
  width: 1000px;
  background-color: #fce8e7;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.two {
  width: 1000px;
  background-color: #fff5d3;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.three {
  width: 1000px;
  background-color: #daf7d0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.prescription-card-records-one{
  width: 98%;
  background-color: #daf7d0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-bottom-text {
  margin: 15px 0 0 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.testName-section {
  font-size: 16px;
}

.card-bottom-text-count {
  float: right;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
}

.appointment-button {
  width: 232px;
  height: 40px;
  cursor: pointer;
  background-color: #4868F6;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  float: right;
  margin: 0 1rem 0 0;
  border-radius: 6px;
  border: none;
  margin: 30px 18px 20px 0;
}

.text-plus {
  display: inline;
  justify-content: space-between;
  align-items: center;
}

.pdf-container {
  width: 100%;
  height: auto;
  margin-top: 50px;
  overflow: hidden;
  background-color: #edf7fd;
  display: flex;
}

.pdf-container-vaccination {
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: #edf7fd;
  display: flex;
}

.pdf-viewer {
  margin-right: 50px;
  flex: 1;
  height: 1000px;
  margin: 1rem;
  border: none;
}

.no-pdf-text {
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  margin: 100px 0 0 0;
}