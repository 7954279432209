.clinic_overlay_popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 1000; */
}

.clinic_pop_popup-content {
    position: fixed;
    top: 54%;
    left: 50%;
    height: 650px;
    width: 40%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    padding: 20px;
    overflow-y: scroll;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.clinic_pop_popup-content::-webkit-scrollbar {
    display: none;
}

.clinic_pop_popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    position: sticky;
    top: -20px;
    background-color: white;
    z-index: 10;
}

.clinic_pop_popup-form label {
    display: block;
    margin-bottom: 10px;
    text-align: left;
}

.clinic_pop_popup-form input,
.clinic_pop_popup-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.css-13cymwt-control {
    margin: 5px 0 5px 0;
}

.clinic_pop_popup-form .react-select__control {
    margin-bottom: 15px;
}

.clinic_pop_close-icon {
    cursor: pointer;
}

.clinic_pop_popup-button {
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 0px;
    border-radius: 4px;
    font-size: 20px;
    margin-top: 30px;
    background-color: #219EBC;
}

.time-slot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
}

.time-slot>input[type="time"] {
    flex: 1;
    outline: none;
    font-size: 19px;
    border-radius: 6px;
    padding: 0.3rem 0 0.3rem 0.8rem;
    border: 1px solid rgb(202, 202, 202);
    box-sizing: border-box;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.clinic-signup-remove-time-slot-button {
    background-color: #c44242;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px 0 5px 0;
}

.clinic-signup-remove-time-slot-button:hover {
    background-color: #9f3636;
}

.clinic-signup-add-time-slot-button {
    background-color: #4caf50;
    margin-bottom: 5px;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.clinic-signup-add-time-slot-button:hover {
    background-color: #45a049;
}