.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup_cross {
  width: 24px;
  height: 24px;
  background-color: none;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.popup_background {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 998;
}

.popup_content {
  background-color: #f6f6f6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  z-index: 999;
  width: 714px;
  height: 560px;
}

.section_1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.password_section {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  box-sizing: border-box;
  flex-direction: column;
  gap: 48px;
  margin: 48px 0 80px 0;
  width: 541px;
}

.password_box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
}

.password_input {
  border: 1px solid #989898;
  box-sizing: border-box;
  border-radius: 6px;
  height: 48px;
  padding: 12px 36px 12px 16px;
  outline: none;
}

.btn_change_password {
  align-self: flex-start;
  margin-left: 86px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #219EBC;
  padding: 12px 40px;
  border-radius: 6px;
  margin-bottom: 64px;
  cursor: pointer;
}

.forgoterror{
  color: red;
  font-size: 14px;
}