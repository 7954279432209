/* General Styles */

.settings_container {
  background-color: #fff;
  top: 88px;
  left: 112px;
  right: 20px;
  bottom: 20px;
  width: auto;
  height: auto;
  position: absolute;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 32px;
  overflow-y: auto;
  text-align: left;
}

.lab_card_container::-webkit-scrollbar,
.assistant_card_container::-webkit-scrollbar,
.settings_container::-webkit-scrollbar {
  display: none;
}

/* //////////////////////////////////////////////////////////////////////// */
/* BUTTONS */

/* BUTTON SAVE */
.main-button_box {
  margin-bottom: 10px;
}

.main-button_box .heading {
  font-size: 35px;
  font-weight: 600;
}

.icon_plus {
  cursor: pointer;
}

.icon_plus_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.medicines {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}


/* BUTTON MEDICINE AND SECURITY */

.btn_doc_profile,
.btn_security_two,
.btn_security,
.btn_medicines {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 479px;
  background-color: #b0e7f1;
  border-radius: 6px;
  padding: 8px 8px 8px 24px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 22px;
  color: #000;
  border: none;
}

.btn_medicines {
  font-weight: normal;
  text-decoration: none;
}

a.btn_medicines::after {
  content: none;
}

/* /////////////////////////////////////////////////////////////////////////////// */
/* PERSONAL INFO SECTION */

.main_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 31px;
  color: #023047;
  font-size: 32px;
  width: auto;
  height: auto;
}

.personal_info_section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  /* border-bottom: 1px solid #989898; */
  padding-bottom: 40px;
}

.heading {
  font-size: 30px;
  font-weight: 600;
  color: #023047;
}

.personal_info_content {
  display: flex;
  gap: 223px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  color: #000;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.personal_info_text {
  position: relative;
  font-weight: 500;
}

.personal_info_input {
  font-weight: 400;
  font-size: 19px;
}

/* //////////////////////////////////////////////////////////////////////////////////// */
/* ASSISTANTS SECTION */

.assistant {
  font-size: 20px;
}

.assistant_1 {
  gap: 40px;
}

/* COMMON*/

.el_inputboxes,
.el_textbox,
.el_textboxes,
.assistant_1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.labels1 {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  height: 160px;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 12px 0;
}

.assistant_1,
.el_textboxes {
  flex-shrink: 0;
}

.el_inputboxes,
.el_textboxes {
  flex-direction: column;
  gap: 8px;
}

.el_textbox {
  background-color: #f6f6f6;
  border: 1px solid #989898;
  border-radius: 6px;
  box-sizing: border-box;
  width: 415px;
  height: 48px;
  flex-direction: column;
  padding: 12px 36px 12px 16px;
}

.assistant_2,
.assistant,
.assistant_section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.assistant_section {
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 40px;
}

.assistant {
  gap: 48px;
  font-size: 20px;
  color: #000;
}

.assistant_text_input,
.assistant_2 {
  color: #989898;
  flex-shrink: 0;
  gap: 16px;
}

.assistant_input_active {
  color: #000;
}

.el_inputbox {
  border: 1px solid #989898;
  border-radius: 6px;
  width: 415px;
  height: 48px;
  padding: 12px 36px 12px 16px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
}

.el_input_text_password {
  -webkit-text-security: disc;
}

.el_inputbox>input {
  border: none;
  outline: none;
  width: 380px;
  height: 48px;
  font-size: 20px;
  font-family: inherit;
}

.el_input_text::placeholder {
  color: #989898;
}



/* /////////////////////////////////////////////////////////////////////////////// */
/* PREFERENCE SECTION */

.preference_section {
  border-bottom: 1px solid #989898;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 40px;
  gap: 24px;
}

.preference_content {
  box-sizing: border-box;
  position: relative;
  width: 479px;
  height: auto;
  font-size: 24px;
  color: #219ebc;
}

.searchbar,
.add_new_lab,
.lab_list,
.lab {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.lab_list,
.lab {
  flex-direction: column;
  flex-shrink: 0;
  gap: 16px;
}

.lab_list_container {
  display: flex;
  gap: 52px;
  font-size: 18px;
  color: #000;
}

.lab_list {
  font-size: 18px;
  color: #989898;
}

.add_new_lab {
  height: 48px;
  font-size: 16px;
  color: #989898;
  gap: 16px;
}

.lab_input_box {
  height: 48px;
  align-self: stretch;
  box-sizing: border-box;
  background-color: #f6f6f6;
  border: 1px solid #989898;
  border-radius: 6px;
  padding: 12px 36px 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.searchbar_box {
  width: 415px;
}

.searchbar {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #989898;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 8px;
}

.el_input_search {
  border: none;
  outline: none;
  width: 380px;
  font-size: 16px;
  font-family: inherit;
}

.el_input_search::placeholder {
  color: #989898;
}



/* /////////////////////////////////////////////////////////////////////////////// */
/* SECURITY SECTION */

.doc_profile_section,
.security_content,
.security_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn_security_text{
  font-size: 25px;
  font-weight: 500;
}

.doc_profile_section,
.security_section {
  width: 1184px;
  box-sizing: border-box;
  gap: 16px;
  padding: 0 0 40px;
}

.doc_profile_section {
  padding: 0 0 0 0;
}

.security_content {
  width: 479px;
  gap: 8px;
}

.btn_doc_profile {
  text-decoration: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////// */

/* Assistant Card */

.assistant_card_container {
  max-width: 540px;
  display: flex;
  gap: 20px;
  overflow: scroll;
}

.lab_card,
.assistant_card {
  width: 160px;
  height: 160px;
  border: 1px solid #989898;
  border-radius: 8px;
  padding: 16px 24px 24px 24px;
  position: relative;
}

.assistant_card_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.assistant_img {
  width: 95px;
  height: 95px;
}

.assistant_cross_icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.assistant_name {
  width: 130px;
  text-align: center;
  overflow: hidden;
}

/* Lab Card */
.lab_card_container {
  max-width: 520px;
  display: flex;
  gap: 20px;
  overflow: scroll;
}

.lab_card {
  padding: 44px 24px 24px 24px;
}

.lab_card_content {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.lab_name {
  width: 130px;
  text-align: center;
  overflow: hidden;
}

.lab_add {
  cursor: pointer;
}

.horizintal-line {
  width: 100%;
}