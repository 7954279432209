.overlay_page-search-doctor {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointmentCalendar {
  position: fixed;
  top: 53%;
  left: 50%;
  height: 650px;
  width: 600px;
  background-color: #f8fbfc;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.appointmentCalendar-content::-webkit-scrollbar {
  display: none;
}

.appointmentCalendar-content {
  padding: 20px;
  margin: 35px 0 0 50px;
  overflow-y: scroll;
}

.appointmentCalendar-content>h2 {
  font-size: 28px;
  font-weight: 600;
}

.appointmentCalendar-content-section {
  margin: 50px 0 0 0;
}

.appointmentCalendar-content-section>p {
  font-size: 16px;
  font-weight: 500;
}

.appointmentCalendar-content-section>input {
  width: 455px;
  height: 48px;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 8px 0 rgba(112, 111, 111, 0.2), 0 6px 20px 0 rgba(136, 136, 136, 0.19);
}

.search-card-appointment_calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  width: 455px;
  border-radius: 8px;
  gap: 30px;
  padding: 5px 20px 20px 20px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(112, 111, 111, 0.2), 0 6px 20px 0 rgba(136, 136, 136, 0.19);
}

.search-card-appointment_calendar>img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 100px;
  border: 1px solid #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2196F3;
}

.search-button {
  width: 455px;
  padding: 15px 0px;
  border-radius: 6px;
  background-color: #4868F6;
  border: none;
  margin: 50px 0 0 0;
  color: white;
  font-weight: 600;
  font-size: 18px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  cursor: pointer;
}