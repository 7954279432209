.education-skills-container::-webkit-scrollbar {
  display: none;
}

.education-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.education-cross {
  width: 24px;
  height: 24px;
  background-color: none;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.education-background {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 998;
}

.education-content {
  background-color: #f6f6f6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  z-index: 999;
  width: 90%;
  max-width: 814px;
  max-height: 90%;
  overflow-y: auto;
}

.education-section-1 {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.education-input {
  width: 100%;
  padding: 12px;
  margin: 0 0 10px 0;
  border-radius: 10px;
  border: 1px solid #000;
}

.education-input-date {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #000;
  cursor: pointer;
}

.education-heading {
  font-size: 24px;
  font-weight: 600;
}

.education-btn-box {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 10px;
}

.education-add-btn{
  font-family: inherit;
  background: none;
  border: none;
  background-color: #219EBC;
  color: #fff;
  padding: 10px 24px;
  cursor: pointer;
  border-radius: 60px;
}

.education-save-btn {
  font-family: inherit;
  background: none;
  border: none;
  background-color: #66d348;
  padding: 10px 24px;
  cursor: pointer;
  border-radius: 60px;
}

.education-cancel-btn {
  font-family: inherit;
  cursor: pointer;
  background: none;
  border: none;
  color: #000;
}

.education-cancel-btn:hover {
  text-decoration: underline;
}

.education-skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: scroll;
}

.education-skill-item {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  max-width: fit-content;
  height: 34px;
  font-size: 18px;
  padding: 4px 16px;
  border-radius: 16px;
  border: 1px solid #000;
  background-color: #f6f6f6;
  white-space: nowrap;
}

.education-spec-cross {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .education-content {
    width: 95%;
    height: 95%;
    border-radius: 16px;
  }

  .education-section-1 {
    padding: 15px;
    gap: 10px;
  }

  .education-heading {
    font-size: 20px;
  }

  .education-input {
    padding: 10px;
  }

  .education-btn-box {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .education-save-btn,
  .education-cancel-btn {
    width: 100%;
    padding: 10px;
  }
}