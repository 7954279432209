.dashboadr-header_container {
    display: flex;
    justify-content: space-between;
}

.div-dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px;
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    left: 300px;
}

.link {
    width: auto;
    padding: 0px 20px;
    color: black;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
}

.link.active {
    width: auto;
    height: 42px;
    display: flex;
    font-weight: 600;
    align-items: center;
    padding: 0px 20px;
    border-radius: 6px;
    justify-content: center;
    color: #fff !important;
    background-color: #3CB9D4;
}

.button {
    gap: 15px;
    width: 30%;
    display: flex;
    position: relative;
    flex-direction: row-reverse;
}


.link {
    font-family: Inter;
}