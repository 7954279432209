.dashboadrNavigation_container {
    background-color: #F8FBFC;
    Width: auto;
    height: auto;
    position: fixed;
    top: 88px;
    left: 112px;
    right: 20px;
    bottom: 10px;
    border-radius: 32px;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
}

.dashboard-section-navigation {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    overflow-y: hidden;
}

.dashboard_dataNotAvilable {
    background-color: #F8FBFC;
    Width: auto;
    height: auto;
    position: fixed;
    top: 88px;
    left: 112px;
    right: 20px;
    bottom: 10px;
    border-radius: 32px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    color: gray;
}